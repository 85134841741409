import React from "react";

function Sidebar({ articles, onArticleClick }) {
  return (
    <div className="sidebar">
      {articles.map((article) => (
        <button
          key={article.id}
          className="sidebar-item"
          onClick={() => onArticleClick(article)}
        >
          {article.title}
        </button>
      ))}
    </div>
  );
}

export default Sidebar;
