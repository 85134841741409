// Contributors.js
import React from "react";

function Contributors() {
  return (
    <div className="page-container contributors-container">
      <h1>Our Contributors</h1>
      <p>
        At Linux Sec Ops, we value the contributions of experts who share their
        knowledge to help our community grow. Our contributors are experienced
        system administrators, security experts, and DevOps engineers who are
        passionate about providing real-world solutions to everyday challenges.
      </p>
      <p>
        If you are interested in contributing articles, tutorials, or guides to
        Linux Sec Ops, please reach out to us at:
      </p>
      <a href="mailto:sandro.devops@gmail.com" className="email-link-animated">
        sandro.devops@gmail.com
      </a>
    </div>
  );
}

export default Contributors;
