import React, { useState } from "react";

function DownloadsBook() {
  const [books] = useState([
    {
      id: 1,
      title: "Black Hat Python Python Programming for Hackers and Pentesters",
      link:
        "/files/Black Hat Python Python Programming for Hackers and Pentesters.pdf",
    },
    {
      id: 2,
      title: "Basics of Penetration Testing",
      link: "/files/Basics of Penetration Testing.pdf",
    },
    {
      id: 3,
      title: "Metasploit The Penetration Tester s Guide",
      link: "/files/Metasploit The Penetration Tester s Guide.pdf",
    },
    {
      id: 4,
      title: "The DevOps Handbook",
      link: "/files/The DevOps Handbook.pdf",
    },
    {
      id: 5,
      title: "Typescript Fundamentals",
      link: "/files/Typescript Fundamentals.pdf",
    },
    {
      id: 6,
      title: "Ubuntu Linux Unleashed 2021 Edition 14th Edition",
      link: "/files/Ubuntu Linux Unleashed 2021 Edition 14th Edition.pdf",
    },
    {
      id: 7,
      title: "The Linux Command Line",
      link: "/files/The Linux Command Line.pdf",
    },
    {
      id: 8,
      title: "TCP IP Foundations",
      link: "/files/TCP IP Foundations.pdf",
    },
    {
      id: 9,
      title: "Linux Command Line And Shell Scripting Bible",
      link: "/files/Linux Command Line And Shell Scripting Bible.pdf",
    },
    {
      id: 10,
      title: "Linux Bible 10th-ed 2020",
      link: "/files/Linux Bible 10th-ed 2020.pdf",
    },
  ]);

  return (
    <div className="page-container">
      <h1>Downloads Book</h1>
      <ul>
        {books.map((book) => (
          <li key={book.id}>
            <a href={book.link} download>
              {book.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DownloadsBook;
