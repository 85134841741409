import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import MarkdownView from "./components/MarkdownView";
import About from "./components/About";
import DownloadsBook from "./components/DownloadsBook";
import Contributors from "./components/Contributors";
import articles from "./components/articlesData"; // Importovanje svih članaka iz articlesData.js
import logo from "./assets/images/LSO.png"; // Importovanje logo slike
import "./App.css"; // Učitavanje CSS-a
import linkedinLogo from "./assets/images/linkedin.png";
import githubLogo from "./assets/images/github.png";

function MainAppContent() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  // Dodato
  const navigate = useNavigate();

  useEffect(() => {
    let filtered = articles;

    // Filtriranje po kategoriji
    if (selectedCategory && selectedCategory !== "All Categories") {
      filtered = filtered.filter(
        (article) => article.category === selectedCategory
      );
    }

    // Filtriranje po pretrazi
    if (searchTerm.trim() !== "") {
      filtered = filtered.filter(
        (article) =>
          article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (article.content &&
            article.content.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredArticles(filtered);
  }, [searchTerm, selectedCategory]);

  const handleArticleClick = (article) => {
    navigate(article.path);
  };

  // Scroll Event Listener to Show Scroll to Top Button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Dropdown zatvaranje pri kliku van menija
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".custom-dropdown")) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownOpen]);

  return (
    <div className="main-layout">
      <div className="sidebar-container">
        {/* Search Box */}
        <div className="search-box-container">
          <input
            type="text"
            placeholder="Search articles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-box"
          />
        </div>
        {/* Dropdown za kategorije */}
        <div className="dropdown-container">
          <div
            className="custom-dropdown"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <span>{selectedCategory || "All Categories"}</span>
            <div
              className={`custom-dropdown-menu ${dropdownOpen ? "open" : ""}`}
            >
              {[
                "All Categories",
                "PROGRAMMING",
                "CYBER SECURITY",
                "NETWORKING",
              ].map((category) => (
                <div
                  key={category}
                  onClick={() => {
                    setSelectedCategory(
                      category === "All Categories" ? "" : category
                    );
                    setDropdownOpen(false);
                  }}
                  className="custom-dropdown-item"
                >
                  {category}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <Sidebar
          articles={filteredArticles}
          onArticleClick={handleArticleClick}
        />
      </div>
      {/* Article View */}
      <div className="article-view">
        <Routes>
          <Route
            path="/"
            element={
              <div
                style={{
                  textAlign: "left",
                  fontSize: "1.5em",
                  lineHeight: "1.8",
                  padding: "20px",
                }}
              >
                <strong>
                  Please select a project from the left to view its content.
                </strong>
                <br />
                <br />
                Linux Sec Ops is dedicated to tackling everyday challenges and
                issues faced by system administrators, security experts, and
                DevOps engineers.
                <br />
                <br />
                In addition to providing technical guidance, Linux Sec Ops also
                emphasizes the importance of non-technical skills essential for
                a successful career in the IT industry.
              </div>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/downloads" element={<DownloadsBook />} />
          <Route path="/contributors" element={<Contributors />} />
          {filteredArticles.map((article) => (
            <Route
              key={article.id}
              path={article.path}
              element={<MarkdownView filePath={article.file} />}
            />
          ))}
        </Routes>
      </div>
      {/* Scroll to Top Button */}
      {showScrollToTop && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          ↑ Back to Top
        </button>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="app-container">
        {/* Gornji header sa logom i navigacionim linkovima */}
        <header className="header">
          <div className="header-content">
            <LogoComponent /> {/* Novi komponent za logo */}
            <div className="header-text">
              Tactical scripting is about precision, not brute force - it's
              writing clean, efficient code that hits the target without
              unnecessary complexity or noise.
            </div>
            <nav className="nav-links">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/downloads">Downloads Book</Link>
              <Link to="/contributors">Contributors</Link>
            </nav>
            {/* Social Links */}
            <div className="social-links-wrapper">
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/in/sandro-radinkovic-18a8a35/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn Logo"
                    className="social-logo"
                  />
                </a>
                <a
                  href="https://github.com/xilpanda"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={githubLogo}
                    alt="GitHub Logo"
                    className="social-logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </header>

        {/* Glavni sadržaj */}
        <MainAppContent />

        {/* Footer */}
        <footer className="footer">
          © 2024 Linux Sec Ops | Đura će ti oprostiti što te tukao, ali greška u
          skripti ne prašta.
        </footer>
      </div>
    </Router>
  );
}

function LogoComponent() {
  const navigate = useNavigate(); // Dodato: Definisanje navigate hook-a

  return (
    <img
      src={logo}
      alt="LSO Logo"
      className="logo"
      onClick={() => navigate("/")} // Direktna navigacija bez zasebne funkcije
      style={{ cursor: "pointer" }}
    />
  );
}

export default App;
