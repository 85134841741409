import React, { useState, useEffect, useRef } from "react";
import "./Certification.css"; // Uveri se da ovaj CSS ne menja globalne stilove

import certificateImage from "../assets/images/certificate.png";

const modules = [
  {
    title: "Introduction to DevOps",
    content:
      "• DevOps principles and culture\n• DevOps practices and methodologies (Agile, CI/CD, SRE)\n• DevOps engineers and tools\n• Collaboration in DevOps\n• Netflix case study",
    icon: "🚀",
  },
  {
    title: "Docker",
    content:
      "• Introduction to Docker\n• Dockerfiles and image creation\n• Docker Compose for multi-container applications\n• Advanced Docker techniques",
    icon: "🐳",
  },
  {
    title: "Kubernetes",
    content:
      "• Kubernetes Architecture\n• Deployment and Scaling\n• Kubernetes Operations and Monitoring\n• Hands-on Lab with Kubernetes",
    icon: "⚙️",
  },
  {
    title: "CI/CD with GitLab",
    content:
      "• CI/CD basics and pipelines\n• GitLab CI/CD configuration\n• Writing CI/CD scripts with YAML\n• Testing and deployment automation",
    icon: "🔄",
  },
];

function Certification() {
  const [openModule, setOpenModule] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const moduleRefs = useRef([]);

  useEffect(() => {
    // Resetuj reference kada se moduli promene
    moduleRefs.current = moduleRefs.current.slice(0, modules.length);
  }, []);

  const toggleModule = (index) => {
    setOpenModule(openModule === index ? null : index);
  };

  const toggleImage = () => {
    setShowFullImage(!showFullImage);
  };

  return (
    <div className="certification-container">
      <h1>Certification</h1>
      <div className="certification-content">
        <div className="modules-container">
          {modules.map((module, index) => (
            <div
              key={index}
              className={`module ${openModule === index ? "active" : ""}`}
              ref={(el) => (moduleRefs.current[index] = el)}
            >
              <button
                className={`module-title ${
                  openModule === index ? "active" : ""
                }`}
                onClick={() => toggleModule(index)}
                aria-expanded={openModule === index}
              >
                <div className="module-title-content">
                  <span className="module-icon">{module.icon}</span>
                  <span className="module-title-text">{module.title}</span>
                </div>
                <span className="module-arrow">
                  {openModule === index ? "−" : "+"}
                </span>
              </button>
              <div
                className={`module-content ${
                  openModule === index ? "open" : ""
                }`}
                aria-hidden={openModule !== index}
              >
                <div className="module-content-inner">
                  <ul>
                    {module.content.split("\n").map((item, i) => (
                      <li key={i}>{item.replace("• ", "")}</li>
                    ))}
                  </ul>
                  <div className="module-details">
                    <div className="progress-bar">
                      <div className="progress" style={{ width: "100%" }}></div>
                    </div>
                    <span className="completion-status">Completed</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="certificate-image-container">
          <img
            src={certificateImage}
            alt="Certificate"
            className={`certificate-image ${showFullImage ? "full-size" : ""}`}
            onClick={toggleImage}
          />
          {showFullImage && (
            <div className="image-overlay" onClick={toggleImage}>
              <span className="close-button">&times;</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Certification;
