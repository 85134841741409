import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // za GitHub-stil formatiranje, kao što su checkboxovi, linkovi, itd.

const MarkdownView = ({ filePath }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (filePath) {
      fetch(filePath)
        .then((response) => response.text())
        .then((text) => setContent(text))
        .catch((error) => console.error("Error loading Markdown file:", error));
    }
  }, [filePath]);

  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>;
};

export default MarkdownView;
