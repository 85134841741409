import React from "react";

function About() {
  return (
    <div className="page-container">
      <h1>About Us</h1>
      <p>
        Welcome to Linux Sec Ops – a hub for tackling real-world security and
        DevOps challenges faced by system administrators, cybersecurity experts,
        and cloud engineers.
      </p>
      <p>
        <strong>Our Mission</strong> is simple: to provide straightforward,
        practical solutions for everyday issues in Linux administration,
        security, and cloud management. From hardening server defenses, managing
        network security, optimizing systems, to automating processes with
        DevOps practices and securing AWS environments, Linux Sec Ops is here to
        support IT professionals in building secure, scalable, and efficient
        infrastructure.
      </p>
      <h3>What We Offer:</h3>
      <ul>
        <li>
          In-depth articles focused on Linux security administration and cloud
          best practices.
        </li>
        <li>
          Step-by-step guides for securing systems, managing network
          configurations, leveraging AWS services, and automating workflows
          using DevOps tools.
        </li>
        <li>
          Real-world use cases and practical tips that help bridge the gap
          between theory and practice.
        </li>
      </ul>
      <p>
        Join us in our journey to make Linux and cloud environments more secure
        and efficient, one solution at a time.
      </p>
    </div>
  );
}

export default About;
