const articles = [
  {
    id: 1,
    title: "Malware Analysis Lab Setup",
    file: require("../content/malwareAnalysis.md"),
    path: "/malware-analysis",
    category: "CYBER SECURITY",
  },
  {
    id: 2,
    title: "Ping Tool",
    file: require("../content/pingTool.md"),
    path: "/ping-tool",
    category: "NETWORKING",
  },
  {
    id: 3,
    title: "Forensic Data Collection Script",
    file: require("../content/forensicData.md"),
    path: "/forensic-data",
    category: "CYBER SECURITY",
  },
  {
    id: 4,
    title: "Network Traffic Analyzer 1",
    file: require("../content/networkTrafficAnalyzer1.md"),
    path: "/network-traffic-analyzer-1", // Jedinstveni path
    category: "NETWORKING",
  },
  {
    id: 5,
    title: "Network Traffic Analyzer 2",
    file: require("../content/networkTrafficAnalyzer2.md"),
    path: "/network-traffic-analyzer-2",
    category: "NETWORKING",
  },
  {
    id: 6,
    title: "Exploitation of the Target Machine Using Metasploit Framework",
    file: require("../content/exploitationMetasploit.md"),
    path: "/exploitation-metasploit",
    category: "CYBER SECURITY",
  },

  {
    id: 7,
    title: "TDD Approach in React TypeScript Apps",
    file: require("../content/tdd-approach.md"),
    category: "PROGRAMMING",
    path: "/tdd-approach",
  },

  {
    id: 8,
    title: "Network Scanning Script",
    file: require("../content/network-scanning-script.md"),
    category: "NETWORKING",
    path: "/network-scanning-script",
  },

  {
    id: 9,
    title: "Penetration Testing",
    file: require("../content/penetration-testing.md"),
    category: "CYBER SECURITY",
    path: "/penetration-testing",
  },

  {
    id: 10,
    title: "Linux System Administration and Automated Tasks",
    file: require("../content/linux-system-admin.md"),
    category: "NETWORKING",
    path: "/linux-system-admin",
  },

  {
    id: 11,
    title: "Setting Up Strapi CMS, AWS Lambda, and Grafana",
    file: require("../content/setting-up-strapi.md"),
    category: "AWS",
    path: "/setting-up-strapi",
  },

  {
    id: 12,
    title: "AWS Deployment Scenarios",
    file: require("../content/aws-deployment-scenarios.md"),
    category: "AWS",
    path: "/aws-deployment-scenarios",
  },

  {
    id: 13,
    title: "AWS Security and Infrastructure Tasks",
    file: require("../content/aws-security-infrastructure.md"),
    path: "/aws-security-infrastructure",
    category: "AWS",
  },
];

export default articles;
